import { Flex, Heading, Link } from 'theme-ui'

import { useTranslate } from '~/hooks'
import { ISlimArchive } from 'graphql-cms/fragments/archive'
import ArchiveMedia from '../Media'

interface ArchiveItemProps extends ISlimArchive {
  isActive?: boolean
  onToggle: () => void
}

const ArchiveItem: React.FC<ArchiveItemProps> = ({
  slug,
  title,
  image,
  imageMobile,
  onToggle,
  isActive,
  //...props
}) => {
  const translate = useTranslate()

  const textColor = isActive
    ? 'var(--gray-normal-one-off)'
    : 'var(--black-one-off)'

  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        padding: ['15px 0px 35px', null, '50px 0px'],
        '&:not(:last-child)': {
          borderBottom: [0, null, '1px solid var(--gray-one-off)'],
        },
        '&:hover .see-drop': {
          opacity: '1',
          pointerEvents: 'all',
        },
      }}
    >
      <ArchiveMedia
        image={image}
        imageMobile={imageMobile}
        containerSx={{
          display: ['block', null, 'none'],
          minHeight: '180px',
          marginBottom: '10px',
          '& img': { minHeight: '180px' },
        }}
      />

      <Heading
        onClick={onToggle}
        sx={{
          fontSize: ['20px', null, '30px'],
          fontFamily: 'Mint Grotesk Display',
          color: ['var(--black-one-off)', null, textColor],
          cursor: 'pointer',
          textAlign: ['center', null, 'left'],
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Heading>
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >

        <Link
          href={`/discover/${slug}`}
          className="see-drop"
          sx={{
            opacity: ['1', null, isActive ? '1' : '0'],
            pointerEvents: ['all', null, isActive ? 'all' : 'none'],
            display: 'block',
            marginTop: ['45px', null, 0],
            fontSize: ['20px', null, '12px'],
            fontFamily: ['Graphik Wide', null, 'Mint Grotesk Display'],
            textAlign: ['center', null, 'left'],
            textTransform: 'uppercase',
            textDecoration: ['underline', null, 'none'],
          }}
        >
          {translate('pages.archive.drop.see')}
        </Link>
      </Flex>
    </Flex>
  )
}

export default ArchiveItem
