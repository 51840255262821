/** @jsxImportSource theme-ui */
import React from 'react'
import { Flex, Heading, Link, Text } from 'theme-ui'
import ProductList from '~/components/Product/List'
import ReactMarkdown from 'react-markdown'
import type { IProduct } from 'graphql-cms/types'
import styles from './styles.module.css'
import { ISales } from 'basta-ai/types'
import { useTranslate } from '~/hooks'

interface Props {
  title: React.ReactNode
  description?: string
  products: IProduct[]
  sales?: ISales[]
  hideActButton?: boolean
}

const ProductSection: React.FC<Props> = ({
  title,
  description,
  products,
  sales,
  hideActButton = false,
}) => {
  const translate = useTranslate()
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: ['100px', '60px', '60px'],
        textAlign: 'center',
      }}
    >
      {title && (
        <Heading
          as="h1"
          variant="h1"
          sx={{
            textAlign: 'center',
            color: 'primary',
            variant: ['text.h2', 'text.h2', 'text.h1'],
            marginTop: ['4px', null, '4px'],
            marginBottom: ['8px', null, '32px'],
          }}
        >
          {title}
        </Heading>
      )}
      {description && (
        <Text
          sx={{
            variant: 'text.link',
            color: 'primary',
            textAlign: 'center',
            paddingBottom: '1rem',
          }}
        >
          <ReactMarkdown>{description}</ReactMarkdown>
        </Text>
      )}
      <ProductList products={products} sales={sales} />

      {!hideActButton && (
        <Link className={styles.seeAllCollectionLink} href="/collections">
          {translate('pages.index.shop_now')}
        </Link>
      )}
    </Flex>
  )
}

export default ProductSection
